<template>
  <router-view />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  components: {
    // SideBar,
  },
};
</script>

<style>

</style>
