<template>
    <div class="flex space-x-4">
        <a target="_blank" href="https://www.facebook.com/profile.php?id=61556080778063" class="text-gray-400 hover:text-white">
            <FacebookSvg class="h-6 w-6"/>
        </a>
        <a target="_blank" href="https://www.instagram.com/sferadigitalmx/" class="text-gray-400 hover:text-white">
            <InstagramSvg class="h-6 w-6 mr-2"/>
        </a>
        <a 
        :href="whatsappLink" 
        target="_blank" 
        rel="noopener noreferrer"
         class="text-gray-400 hover:text-white">
            <WhatsappSvg class="h-6 w-6"/>
        </a>
        <a target="_blank" href="https://www.tiktok.com/@sferadigitalmx" class="text-gray-400 hover:text-white">
            <TikTokSvg class="h-6 w-6"/>
</a>
        <!-- Add more social media links as needed -->
    </div>
        
    
</template>

<script>
    import TikTokSvg from '@/components/images/TikTokSvg.vue';
    import WhatsappSvg from '@/components/images/WhatsappSvg.vue';
    import FacebookSvg from '@/components/images/FacebookSvg.vue';
    import InstagramSvg from '@/components/images/InstagramSvg.vue';

    export default {
        name: 'SocialMediaLinks',
        components: {
            TikTokSvg,
            WhatsappSvg,
            FacebookSvg,
            InstagramSvg,
        },
        data() {
            return {
                phoneNumber: '523319037651', // Replace with your desired phone number
                message: 'Hola, me gustaria conocer mas de sus servicios.' // Replace with your desired message
            };
        },
        computed: {
            whatsappLink() {
                const baseUrl = 'https://wa.me/';
                const encodedMessage = encodeURIComponent(this.message);
                return `${baseUrl}${this.phoneNumber}?text=${encodedMessage}`;
            }
        }
    }

</script>