<template>
  <main class="bg-white dark:bg-slate-900">
    <NavBar/>
    <HeroComponent class="mt-16">
        <div class="text-white text-3xl md:text-5xl font-bold mt-6 mb-6">
          {{ $t('labels.contact') }}
        </div>
        <div class="text-md text-white text-center w-2/3 mb-10">
          ¡Nos encantaría saber de ti! Ya sea que tengas una duda,  pregunta o comentario  ¡Estamos aquí para ayudarte! No dudes en ponerte en contacto con nosotros a través del formulario a continuación o a través de nuestras redes sociales.
        </div>
        <div class="text-md text-white text-center mb-10">
          ¡Esperamos con ansias escucharte!
        </div>
    </HeroComponent>
    <HeroComponent :background-color="'bg-zinc-100'">
      <p class="text-black text-center text-md md:text-lg font-bold mb-10">Escribenos</p>
      <div class="grid grid-cols-1 md:grid-cols-3 w-full md:w-3/4">
        <div class="text-black text-center text-md md:text-lg font-bold mb-4 md:mb-10">
          <div class="mb-4">Teléfono</div>
          <div>
            (+52) 331-903-7651
          </div>
        </div>
        <div class="text-black text-center text-md md:text-lg font-bold mb-4 md:mb-10">
          <div class="mb-4">Email</div>
          <div>  
            contacto@sfera-digital.com
          </div>
        </div>
        <div class="text-black text-center text-md md:text-lg font-bold mb-4 md:mb-10 ">
          <div class="mb-4">Redes Sociales</div>
          <SocialMediaLinks class="justify-center"/>
        </div>
      </div>
      <form @submit.prevent="submit()" class="w-2/3">
        <div class="grid grid-cols-1 md:grid-cols-3 w-full gap-x-4 mb-4">
          <div class="">
            <label class="block text-sm font-medium mb-1" for="name">{{ 'Nombre' }}<span class="text-rose-500">*</span></label>
            <input id="name" class="form-input w-full" type="text" placeholder="John" v-model="formData.name" required />
          </div>
          <div >
            <label class="block text-sm font-medium mb-1" for="lastName">{{ 'Apellido' }}<span class="text-rose-500">*</span></label>
            <input id="lastName" class="form-input w-full" type="text" placeholder="Doe" v-model="formData.lastName" required />
          </div>
          <div >
            <label class="block text-sm font-medium mb-1" for="email">{{ 'Email' }}<span class="text-rose-500">*</span></label>
            <input id="email" class="form-input w-full" type="email" placeholder="john@doe.com" v-model="formData.email" required />
          </div>
        </div>
        
        <label class="block text-sm font-medium mb-1" for="services">{{ 'Servicios' }}<span class="text-rose-500">*</span></label>
        <div class="grid grid-cols-1 md:grid-cols-3 w-full gap-x-4 mb-4 gap-y-4" >
          <div
          class="text-xs text-black"
          v-for="service in services"
          :key="service.title">
            <label class="block mb-1">{{ $t(service.title) }}</label>
            <div v-for="subservice in service.subservices" :key="subservice.title">
              <input class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" type="checkbox"  @change="updateModel(subservice.title)"> 
              {{ subservice.title}}
            </div>
          </div>
        </div>
        <div class="sm:col-span-6 mb-6" >
            <label class="block text-sm font-medium mb-1" for="message">Mensaje <span class="text-rose-500">*</span></label>
            <textarea id="message" v-model="formData.message" class="form-textarea w-full px-2 py-1" rows="4" required></textarea>
        </div>
        <div class="text-center mb-6">

          <button type="submit" class="btn bg-black hover:bg-zinc-400 text-white ml-3">
            <span v-if="!isLoading">{{ $t('buttons.submit') }}</span>
            <span v-else>
                <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
          </button>
        </div>
      </form>
    </HeroComponent>
    <FooterComponent/>
  </main>
</template>

<script>
import Swal from 'sweetalert2';

import LogoSvg from '../components/LogoSvg.vue'
import api from '@/interceptors/axiosInterceptos'; 
import NavBar from '@/partials/NavBar.vue';
import {services} from '@/utils/services.js';
import HeroComponent from '@/components/HeroComponent.vue';
import FooterComponent from '@/partials/FooterComponent.vue';
import SocialMediaLinks from '@/partials/SocialMediaLinks.vue';

export default {
  name: 'ContactPage',
  components: {
    NavBar,
    HeroComponent,
    FooterComponent,
    SocialMediaLinks,
  },
  data() {
    return {
      isLoading: false,
      attemptCount: 0,
      formData: {
        name: '',
        lastName: '',
        email: '',
        message: '',
        services: []
      },
      services: [
        {title: 'labels.digitalMarketing', subservices: services.digitalMarketing},
        {title: 'labels.offlineMarketing', subservices: services.offlineMarketing},
        {title: 'labels.socialMedia', subservices: services.socialMedia},
        {title: 'labels.webDevelopment', subservices: services.webDevelopment},
        {title: 'labels.appDevelopment', subservices: services.appDevelopment}
      ]
    };
  },
  methods: {
    async submit() {
      try {
        if(this.attemptCount > 0){
          Swal.fire({
            icon: 'info',
            title: 'Atencion!',
            text: 'Ya hemos recibido tu solicitud, en tu correo encontraras la confirmacion',
          })
          return;
        }
        this.isLoading = true;
        const token = localStorage.getItem('token');
        const headers = { 
            "apiKey": `ca77e856-9276-4ad5-a5ac-3087f005a26e`,
        };
        // TODO: transform this.formData into the required object
        const response = await api.post(`${process.env.API_URL}/landing-page/`,  this.formData, { headers });
        if(response.status == 200){
            this.attemptCount++;                    
            Swal.fire({
                icon: 'success',
                title: 'Exito!',
                text: 'Se ha enviado la solicitud con exito.',
            });
            this.isLoading = false;
        }
          
      } catch (error) {
        this.isLoading = false;
        Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.response.data['error'] ?? error.response.data['message'],
        })
        this.isLoading = false;
      }

    },
    updateModel(option){
      const index = this.formData.services.indexOf(option);
      if (index === -1) {
        // If the option is not already in the array, push it
        this.formData.services.push(option);
      } else {
        // If the option is already in the array, remove it
        this.formData.services.splice(index, 1);
      }
    }
  },
  
}
</script>