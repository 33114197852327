<template>
  <nav class="bg-black fixed top-0 w-full z-50">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center md:hidden">
          <!-- Mobile menu button-->
          <button 
            @click="toggleMobileMenu" 
            type="button" 
            class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" 
            aria-controls="mobile-menu" 
            aria-expanded="false">
            <span class="absolute -inset-0.5"></span>
            <span class="sr-only">Open main menu</span>
            <svg v-if="!mobileMenuOpen" class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <svg v-else class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <!-- Logo for desktop -->
        <div class="hidden md:flex md:left-0 md:inline">
          <div class="flex flex-shrink-0 items-center">
            <LogoWTextSvg/>
          </div>
        </div>
        <!-- Logo for mobile -->
        <div class="flex flex-1 items-center justify-center md:hidden">
          <div class="sm:flex sm:flex-1 sm:items-center sm:justify-center py-1.5 rounded shadow-lg">
            <LogoWTextSvg/>
          </div>
        </div>
        <div class="hidden sm:block">
          <div class="flex">
            <div 
              v-for="item in menuItems" 
              :key="item.label" 
              class="relative"
              @mouseover="item.submenu ? showDropdown(item.label) : hideDropdownWithDelay()"
              @mouseleave="hideDropdownWithDelay">
              <a 
                :href="item.href" 
                :class="[item.active ? 'bg-zinc-400 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 text-base font-medium']" 
                :aria-current="item.active ? 'page' : undefined">
                {{ $t(item.label) }}
              </a>
              <!-- Dropdown menu for desktop -->
              <div 
                v-if="item.submenu && dropdownOpen === item.label" 
                class="absolute left-1/2 transform -translate-x-1/2 mt-2 w-48 bg-black shadow-lg z-10"
                @mouseover="clearHideDropdownTimeout"
                @mouseleave="hideDropdownWithDelay">
                <div class="py-1" role="menu">
                  <a 
                    v-for="subItem in item.submenu" 
                    :key="subItem.label" 
                    :href="subItem.href" 
                    class="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white" 
                    role="menuitem">
                    {{ $t(subItem.label) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div v-if="mobileMenuOpen" class="sm:hidden" id="mobile-menu">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <div v-for="item in menuItems" :key="item.label">
          <a v-if="!item.submenu"
            :href="item.href" 
            :class="[item.active ? 'bg-zinc-400 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium']" 
            :aria-current="item.active ? 'page' : undefined">
            {{ $t(item.label) }}
          </a>
          <!-- Toggle button for submenu in mobile -->
          <button v-if="item.submenu" @click="toggleSubmenu(item.label)" class="block w-full text-left px-3 py-2 text-gray-300 hover:bg-gray-700 hover:text-white">
            <span class="flex items-center justify-between">
              <span>{{ $t(item.label) }}</span>
              <svg :class="{'transform rotate-180': mobileSubmenuOpen[item.label]}" class="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </span>
          </button>
          <div v-if="item.submenu && mobileSubmenuOpen[item.label]" class="space-y-1 pl-5">
            <a 
              v-for="subItem in item.submenu" 
              :key="subItem.label" 
              :href="subItem.href" 
              class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
              {{ $t(subItem.label) }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import LogoWTextSvg from '@/components/LogoWTextSvg.vue';

export default {
  name: 'NavBar',
  props: ['sidebarOpen'],
  components: {
    LogoWTextSvg
  },
  setup(props, { emit }) {
    const mobileMenuOpen = ref(false);
    const dropdownOpen = ref(null);
    const hideDropdownTimeout = ref(null);
    const mobileSubmenuOpen = ref({});
    const router = useRouter();
    const currentRoute = useRouter().currentRoute.value

    const menuItems = computed(() => [
      { 
        label: 'labels.home', 
        href: '/', 
        active: currentRoute.fullPath === '/' 
      },
      { 
        label: 'labels.aboutUs', 
        href: '/aboutus', 
        active: currentRoute.fullPath === '/aboutus' 
      },
      { 
        label: 'labels.services', 
        href: '/#services', 
        active: currentRoute.fullPath.includes('services'),
        submenu: [
          { label: 'labels.digitalMarketing', href: '/services/digital-marketing' },
          { label: 'labels.socialMedia', href: '/services/social-media' },
          { label: 'labels.offlineMarketing', href: '/services/offline-marketing' },
          { label: 'labels.webDevelopment', href: '/services/web-development' },
          { label: 'labels.appDevelopment', href: '/services/app-development' },
        ] 
      },
      { 
        label: 'labels.clients', 
        href: '/#clients', 
        active: currentRoute.fullPath === '/clients' 
      },
      { 
        label: 'labels.contact', 
        href: '/contact', 
        active: currentRoute.fullPath === '/contact' 
      },
    ]);

    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value;
    };

    const toggleSubmenu = (label) => {
      mobileSubmenuOpen.value[label] = !mobileSubmenuOpen.value[label];
    };

    const showDropdown = (label) => {
      clearHideDropdownTimeout();
      dropdownOpen.value = label;
    };

    const hideDropdownWithDelay = () => {
      hideDropdownTimeout.value = setTimeout(() => {
        dropdownOpen.value = null;
      }, 200);
    };

    const clearHideDropdownTimeout = () => {
      if (hideDropdownTimeout.value) {
        clearTimeout(hideDropdownTimeout.value);
        hideDropdownTimeout.value = null;
      }
    };

    const trigger = ref(null);
    const sidebar = ref(null);

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const sidebarExpanded = ref(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    const clickHandler = ({ target }) => {
      if (!sidebar.value || !trigger.value) return;
      if (
        !props.sidebarOpen ||
        sidebar.value.contains(target) ||
        trigger.value.contains(target)
      ) return;
      emit('close-sidebar');
    };

    const keyHandler = ({ keyCode }) => {
      if (!props.sidebarOpen || keyCode !== 27) return;
      emit('close-sidebar');
    };

    onMounted(() => {
      document.addEventListener('click', clickHandler);
      document.addEventListener('keydown', keyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler);
      document.removeEventListener('keydown', keyHandler);
    });

    watch(sidebarExpanded, () => {
      localStorage.setItem('sidebar-expanded', sidebarExpanded.value);
      if (sidebarExpanded.value) {
        document.querySelector('body').classList.add('sidebar-expanded');
      } else {
        document.querySelector('body').classList.remove('sidebar-expanded');
      }
    });

    watch(router.currentRoute, () => {
      mobileMenuOpen.value = false;
    });

    return {
      trigger,
      sidebar,
      sidebarExpanded,
      mobileMenuOpen,
      toggleMobileMenu,
      menuItems,
      dropdownOpen,
      showDropdown,
      hideDropdownWithDelay,
      clearHideDropdownTimeout,
      mobileSubmenuOpen,
      toggleSubmenu,
    };
  },
};
</script>

<style scoped>
/* Add any additional styles you need here */
</style>
