<template>
  <footer class="py-8" :class="computedClasses">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center">
      <slot></slot>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'HeroComponent',
  props: {
    backgroundColor: {
      type: String,
      default: 'bg-[#909090]' // Default background color
    }
  },
  computed: {
    computedClasses() {
      return this.backgroundColor;
    }
  },
  data(){
    return {
      
    }
  }
}
</script>

<style scoped>
/* Add any additional styles you need here */
</style>
