export const services = {
    digitalMarketing: [
        {
          title: 'Desarrollo de Estrategias', content: ' Evaluamos la situación actual de marketing digital de tu negocio y de la competencia para crear un plan de marketing personalizado y alineado con tus objetivos. Identificamos tu público objetivo y el contenido que más fomenta la interacción con la audiencia.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717899770/marketing-digital-1_dqlr9t.png',
          left: false
        },
        {
          title: 'Mail Marketing', content: ' Diseñamos y gestionamos campañas de correo electrónico persuasivas y atractivas para nutrir la relación con los clientes, promover tus productos y/o servicios, generando una fidelidad mayor a tu audiencia. Monitoreamos y análizamos las métricas de las campañas para optimización continua.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717899758/marketing-digital-2_uefj6w.png',
          left: true
        },
        {
          title: 'Publicidad en Motores de Busqueda (SEM)', content: 'Gestionamos campañas de publicidad de pago por clic (PPC) en motores de búsqueda como Google Ads y Bing Ads. ​Investigamos las palabras clave relevantes para atraer tráfico calificado a tu sitio web. Ajustamos continuamente los anuncios para maximizar el rendimiento y el retorno de inversión (ROI).',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717899770/marketing-digital-3_tfepjq.png',
          left: false
        },
        {
          title: 'Optimización de Motores de Busqueda (SEO)', content: ' Mejoramos el contenido y la estructura del sitio web para mejorar su clasificación en los motores de búsqueda. Optimizamos estrategias de link building y gestión de menciones en otros sitios web. Evaluamos y corregimos los problemas técnicos que puedan afectar la visibilidad en los motores de búsqueda.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717899762/marketing-digital-4_zuw9cn.png',
          left: true
        },
        {
          title: 'Marketing de Influencers', content: ' Seleccionamos personas influyentes relacionadas a tu servicio y/o producto. Nos encargamos de coordinar de inicio a fin las campañas con los influencers de tu elección. Evaluamos el rendimiento y el impacto de las campañas de influencers.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717899765/marketing-digital-5_hf0g8h.png',
          left: false
        },
    ],
    offlineMarketing: [
        {
          title: 'Diseño de Publicidad Impresa', content: 'Ofrecemos servicios de diseño gráfico para crear materiales impresos como folletos, volantes, carteles, tarjetas de presentación, papelería corporativa, y otros elementos visuales que refuercen la identidad de marca de nuestros clientes.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717958549/offline-marketing-1_ivp4qe.png',
          left: false
        },
        {
          title: 'Publicidad en Medios Tradicionales', content: 'Ayudamos a nuestros clientes a planificar y ejecutar campañas de publicidad en medios tradicionales como periódicos, revistas, radio y televisión. Esto puede incluir el desarrollo de estrategias, la compra de espacios publicitarios, la creación y diseño de anuncios, la negociación de tarifas y más.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717958549/offline-marketing-2_gqgx4r.png',
          left: true
        },
        {
          title: 'Relaciones Públicas', content: 'Monitoreamos y mejoramos la percepción pública de la marca. Desarrollamos estrategias para manejar y mitigar situaciones de crisis que puedan afectar la imagen de la empresa. Esto puede incluir la redacción y distribución de comunicados de prensa, la organización de entrevistas y eventos de relaciones públicas. Además, identificamos y gestionamos oportunidades de patrocinio que se alineen con la marca.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717958552/offline-marketing-3_r3gj5m.png',
          left: false
        },
        {
          title: 'Organización de Eventos', content: 'Diseñamos y organizamos tu evento corporativo lanzamientos de productos, conferencias inauguraciones, ferias comerciales, entre otros eventos.. Gestionamos todos los aspectos logísticos, incluyendo el lugar, el catering, y los equipos necesarios. Desarrollamos estrategias para promocionar eventos y asegurar una alta participación.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717975606/offline-marketing-4_oyqi2a.png',
          left: true
        },
        {
          title: 'Marketing de Guerrilla', content: ' Desarrollamos campañas de marketing innovadoras y de alto impacto que sorprendan y capten la atención del público. Implementamos campañas en espacios públicos, utilizando técnicas creativas y poco convencionales, con actividades que involucren directamente al público y generen una experiencia memorable.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717975611/offline-marketing-5_geqtya.png',
          left: false
        },
    ],
    socialMedia: [
        {
          title: 'Gestión de Redes Sociales', content: ' Ofrecer un servicio completo de gestión de redes sociales donde se crea y administra los perfiles en plataformas como Facebook, Instagram, LinkedIn, Google Maps, Tripadvisor, Whatsapp, según las necesidades del negocio. ​Seguimiento de comentarios, mensajes y menciones, y respuesta a interacciones en tiempo real. Uso de herramientas de análisis para medir el éxito de las publicaciones y campañas.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717958734/social-media-1_pnejnr.png',
          left: false
        },
        {
          title: 'Desarrollo de Estrategias', content: ' Ayudamos a nuestros clientes a definir sus objetivos en las redes sociales y crear estrategias específicas para alcanzarlos. Evaluamos la presencia actual en redes sociales, realizamos un análisis de competidores y una segmentación del público objetivo para campañas más efectivas. Desarrollamos planes de contenido que resuenen con la audiencia y fomenten la interacción con el negocio.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957192/social-media-2png_xa4sjw.png',
          left: true
        },
        {
          title: 'Publicidad', content: 'Creamos y gestionamos campañas de publicidad pagada en plataformas como Facebook, Instagram, Whatsapp, entre otras. Utilizamos datos demográficos y de comportamiento para dirigir anuncios a audiencias específicas. Monitoreamos  continuamente los anuncios anuncios para maximizar el rendimiento y el retorno de inversión. Entregamos un informe detallados sobre el rendimiento de las campañas publicitarias.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717958735/social-media-3_skz6wm.png',
          left: false
        },
        {
          title: 'Creacion de Contenido', content: ' Deja de utilizar contenido de otras paginas! Nosotros te diseñamos imágenes, infografías, y videos atractivos y relevantes para tu público, con descripciones persuasivas y coherentes con la voz de tu marca.  Post para facebook, Reels para instagram, manejamos todos los formatos de foto y video de acuerdo a cada red social. ',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957192/social-media-4_igvbq0.png',
          left: true
        },
        {
          title: 'Capacitacion y Consultoria', content: ' Ofrecemos sesiones de capacitación y asesoramiento personalizado a los clientes que deseen aprender a gestionar sus propias redes sociales de manera efectiva. Evaluamos y analizamos el rendimiento actual en tus redes sociales y te ofrecemos una guía de mejoras especificas para tu negocio. Asistencia y asesoría regular para asegurar la implementación efectiva de estrategias en tus redes sociales.',
          image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957191/social-media-5_oon75h.png',
          left: false
        },
    ],
    webDevelopment: [
      {
        title: ' Desarrollo  de Sitios Web', content: ' Creamos sitios web personalizados que reflejen la identidad de marca de nuestro cliente, integrando funcionalidades específicas según las necesidades del negocio como formularios, sistemas de reservas, integraciones con redes sociales, chatbots, asistentes, CRM´s y dashboards. Además creamos sitios web especializados como  sitios educativos, sitios de noticias y blogs, portafolios digitales y plataformas de membresía, entre otros.',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717964067/web-development-1_h662gi.png',
        left: false
      },
      {
        title: 'Optimización para Móviles', content: 'Diseñamos y/u optimizamos tu sitio web con un diseño responsivo para smartphones y tablets, con una interfaz intuitiva y accesible para una fácil navegación para los usuarios en cualquier dispositivo móvil. Asegurando una carga rápida, incluso en redes móviles. Adaptamos botones, menús y formularios táctiles, adecuamos tipografías legibles y texto adecuado para pantallas pequeñas. ',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717963688/web-development-2_tjhlny.png',
        left: true
      },
      {
        title: 'Tiendas en Línea (E-commerce)', content: 'Creamos tiendas online seguras, confiables y personalizadas que se adaptan a las necesidades de tu negocio,  Integramos tu tienda en línea con las principales pasarelas de pago y herramientas avanzadas para gestionar eficientemente tus productos, pedidos y clientes. Aumenta tus ventas llegando a nuevos clientes en todo el mundo las 24 horas del día, facilitando la compra online y optimizando la experiencia del cliente. ',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717963690/web-development-3_jqq4b4.png',
        left: false
      },
      {
        title: 'Integración de Funcionalidades Específicas', content: 'Integramos herramientas y funcionalidades clave para mejorar la experiencia del usuario y la eficacia del sitio web. Como integración de bases de datos robustas, implementación de APIs y microservicios, seguridad y autenticación avanzada, gestión de usuarios y permisos, entre otros. Nos aseguramos de que tu plataforma tenga todas las herramientas necesarias para alcanzar tus objetivos de negocio. ',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717963687/web-development-4_qirgrp.png',
        left: true
      },
      {
        title: 'Mantenimiento y Soporte', content: 'Proporcionamos actualizaciones de seguridad, corrección de errores y soporte técnico continuo. Además, monitoreamos el rendimiento de tu sitio web, realizamos informes detallados para garantizar que tu sitio funcione sin problemas y esté siempre optimizado para el éxito de tu negocio ',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717964939/web-development-5_cr4lk9.png',
        left: false
      },
    ],
    appDevelopment: [
      {
        title: 'Apps Moviles', content: ' Realizamos la app que tu negocio necesita. Ofrecemos un servicio integral de desarrollo de apps móviles, creando aplicaciones tanto nativas para iOS y Android como multiplataforma utilizando tecnologías como Java Script y Flutter, asegurando un alto rendimiento y compatibilidad en todos los dispositivos. Además, proporcionamos servicios de actualización y mantenimiento para mantener tus apps al día con las últimas tecnologías.',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717973437/app-development-1_veuqly.png',
        left: false
      },
      {
        title: 'Apps Webs', content: 'Desarrollamos aplicaciones progresivas (PWA) que funcionan perfectamente en cualquier dispositivo y navegador, desde smartphones hasta desktops. Implementamos soluciones completas que abarcan tanto el front-end como el back-end, integrándolas con servicios en la nube para mayor eficiencia y escalabilidad. Optimizamos las aplicaciones web para SEO, asegurando que sean fácilmente encontradas por los motores de búsqueda. ',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717972807/app-development-2_icbexn.png',
        left: true
      },
      {
        title: 'Diseño de UI/UX', content: 'Nuestro enfoque en diseño UI/UX se centra en la investigación de usuarios para entender sus necesidades y comportamientos, creando wireframes y prototipos interactivos para visualizar el diseño. Realizamos pruebas de usabilidad con usuarios reales para asegurarnos de que la interfaz sea intuitiva y atractiva, optimizando así la experiencia del usuario. ',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717972726/app-development-3_wafyli.png',
        left: false
      },
      {
        title: 'Desarrollo de Back-end', content: 'Creamos y gestionamos APIs para facilitar la comunicación entre el front-end y el back-end, diseñando bases de datos seguras y eficientes. Implementamos sistemas de autenticación y seguridad para proteger los datos de los usuarios y aseguramos la escalabilidad de las aplicaciones para que puedan crecer con tu negocio. ',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717973384/app-development-4_womnip.png',
        left: true
      },
      {
        title: 'Pruebas y Despliegue', content: 'Nuestros servicios de pruebas y despliegue incluyen pruebas unitarias e integrales para verificar el funcionamiento de todos los componentes, así como pruebas de rendimiento y seguridad para garantizar que la aplicación sea rápida y segura. Realizamos pruebas de usabilidad para asegurar que la aplicación sea fácil de usar y nos encargamos del despliegue y lanzamiento, asegurando una transición suave desde el desarrollo hasta el uso real. ',
        image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717973447/app-development-5_ytse7l.png',
        left: false
      },
    ]
}