<template>
  <main class="bg-white dark:bg-slate-900">
    <NavBar/>
    <HeroComponent class="mt-16">
        <div class="text-white text-3xl md:text-5xl font-bold mt-6 mb-6">
          {{ $t('labels.socialMedia') }}
        </div>
    </HeroComponent>
    <HeroComponent :background-color="'bg-zinc-100'">
      <p class="text-black text-center text-md md:text-lg font-bold mb-10">Plataformas</p>
      <div class="flex justify-center">
        <div class=" hidden md:grid grid-cols-5 gap-x-5 md:gap-x-10">
          <img
            v-for="image in images"
            :key="image.id"
            :src="image.image"
            class="rounded-xl mb-10"
            :class="image.color"
            style="width: auto; height: 50px;"
            alt="Icon"
          >
        </div>
      </div>
      <div class="flex justify-center">
        <div class=" grid md:hidden grid-cols-4 gap-x-5 md:gap-x-10">
          <img
            v-for="image in images.slice(0,8)"
            :key="image.id"
            :src="image.image"
            class="rounded-xl mb-10"
            :class="image.color"
            style="width: auto; height: 50px;"
            alt="Icon"
          >
        </div>
      </div>
      <div class="flex justify-center">
        <div class=" grid md:hidden grid-cols-2 gap-x-5 md:gap-x-10">
          <img
            v-for="image in images.slice(8,10)"
            :key="image.id"
            :src="image.image"
            class="rounded-xl mb-10"
            :class="image.color"
            style="width: auto; height: 50px;"
            alt="Icon"
          >
        </div>
      </div>
    </HeroComponent>
    <ServicesTemplate 
    :title="$t('labels.socialMedia')" :services="services"
    />
    <FooterComponent/>
  </main>
</template>

<script>
import Swal from 'sweetalert2';

import NavBar from '@/partials/NavBar.vue';
import { services } from '@/utils/services';
import HeroComponent from '@/components/HeroComponent.vue';
import FooterComponent from '@/partials/FooterComponent.vue';
import ServicesTemplate from '@/partials/ServicesTemplate.vue';

export default {
  name: 'SocialMediaPage',
  components: {
    NavBar,
    HeroComponent,
    FooterComponent,
    ServicesTemplate
  },
  data() {
    return {
      services: services.socialMedia,
      images: [
            {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957190/tik-tok_fho42r.png', color: null},
            {id: 2, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957190/linkedin_hvt3no.png', color: null},
            {id: 3, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957190/instagram_sriupx.png', color: null},
            {id: 4, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957190/facebook_xkeed4.png', color: null},
            {id: 5, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957190/whatsapp_i8rbp9.png', color: null},
          
            {id: 6, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957191/tripadvisor_yp3vo7.png', color: 'bg-green-500'},
            {id: 7, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957190/youtube_y0kxgg.png', color: null},
            {id: 8, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957190/pinterest_hcsihu.png', color: null},
            {id: 9, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957190/x_xv31nv.png', color: null},
            {id: 10, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717957190/google-maps_mexsmp.png', color: null},
          ]
       
    };
  },
  methods: {
    },
  
}
</script>