<template>
  <main class="bg-white dark:bg-slate-900">
    <NavBar/>
    <HeroComponent class="mt-16">
        <div class="text-white text-3xl md:text-5xl font-bold mt-6 mb-6">
          {{ $t('labels.digitalMarketing') }}
        </div>
    </HeroComponent>
    <ServicesTemplate 
    :title="$t('labels.digitalMarketing')" :services="services"
    />
    <FooterComponent/>
  </main>
  </template>
  
  <script>
  import Swal from 'sweetalert2';
  
  import NavBar from '@/partials/NavBar.vue';
  import {services} from '@/utils/services.js';
  import HeroComponent from '@/components/HeroComponent.vue';
  import FooterComponent from '@/partials/FooterComponent.vue';
  import ServicesTemplate from '@/partials/ServicesTemplate.vue';
  
  export default {
    name: 'DigitalMarKetingPage',
    components: {
      NavBar,
      HeroComponent,
      FooterComponent,
      ServicesTemplate
    },
    data() {
      return {
        services: services.digitalMarketing
      };
    },
    methods: {
      },
    
  }
  </script>