<template>
  <main class="bg-white dark:bg-slate-900">
    <NavBar/>
    <HeroComponent class="mt-16" :background-color="'bg-zinc-100'">
      <div class="text-black text-3xl md:text-5xl font-bold mb-6 text-center mt-6">
        Nosotros
      </div>
      <div class="text-sm text-black text-justify w-full py-4 md:w-1/2 mb-6">
        En Sfera Digital, nos enorgullecemos de ser mucho más que una agencia de marketing. Somos tus aliados estratégicos en el mundo digital, comprometidos a brindarte orientación y soluciones efectivas en todo México y América del Sur.
      </div>
    </HeroComponent>
    <HeroComponent :background-color="'bg-zinc-100'">
      <div class="text-black text-3xl md:text-5xl font-bold mb-6 text-center">
        Misión
      </div>
      <div class="text-sm text-black text-justify w-full py-4 md:w-1/2 mb-6">
        Estamos aquí para ayudarte a transformar tu negocio en la era digital. Nos esforzamos por ser tu socio confiable, utilizando las últimas tecnologías y estrategias innovadoras para impulsar tu presencia en línea, aumentar tu visibilidad y generar más ventas para tu negocio.
      </div>
    </HeroComponent>
    <HeroComponent :background-color="'bg-zinc-100'">
      <div class="text-black text-3xl md:text-5xl font-bold mb-6 text-center">
        Visión
      </div>
      <div class="text-sm text-black text-justify w-full py-4 md:w-1/2 mb-8">
        En Sfera Digital, visualizamos un futuro donde cada negocio tenga las herramientas y el conocimiento necesario para triunfar en el mundo digital. Nos esforzamos por ser líderes en la industria, brindando resultados excepcionales a nuestros clientes y marcando el camino hacia el éxito en el mercado digital.
      </div>
    </HeroComponent>
    <FooterComponent/>
  </main>
</template>

<script>
import Swal from 'sweetalert2';

import LogoSvg from '../components/LogoSvg.vue'
import api from '../interceptors/axiosInterceptos'; 
import NavBar from '@/partials/NavBar.vue';
import LogoWTextSvg2 from '@/components/LogoWTextSvg2.vue';
import HeroComponent from '@/components/HeroComponent.vue';
import FooterComponent from '@/partials/FooterComponent.vue';

export default {
  name: 'AboutusPage',
  components: {
    NavBar,
    HeroComponent,
    FooterComponent
  },
  data() {
    return {
      
    };
  },
  methods: {
    },
  
}
</script>