<template>
  <main class="bg-white dark:bg-slate-900">
    <NavBar/>
    <HeroComponent>
      ClientsPage
    </HeroComponent>
    <FooterComponent/>
  </main>
</template>

<script>
import Swal from 'sweetalert2';

import LogoSvg from '../components/LogoSvg.vue'
import api from '../interceptors/axiosInterceptos'; 
import NavBar from '@/partials/NavBar.vue';
import LogoWTextSvg2 from '@/components/LogoWTextSvg2.vue';
import HeroComponent from '@/components/HeroComponent.vue';
import FooterComponent from '@/partials/FooterComponent.vue';

export default {
  name: 'ClientsPage',
  components: {
    NavBar,
    HeroComponent,
    FooterComponent
  },
  data() {
    return {
      
    };
  },
  methods: {
    },
  
}
</script>