<template>
  <main class="bg-white dark:bg-slate-900">
    <NavBar/>
    <HeroComponent class="mt-16">
      <LogoWTextSvg2 class="block md:hidden h-11 w-auto mt-6 mb-20 mr-10 text-center"/>
      <LogoWTextSvg2 class="hidden md:block h-13 w-auto mt-6 mb-20 mr-10 text-center"/>
      <div class="text-white text-md md:text-2xl mb-6 text-center">
        Utiliza las nuevas tecnologías a tu favor.
      </div>
      <div class="text-white text-2xl md:text-5xl font-bold mb-6 text-center">
        ¡Atrae más clientes a tu negocio!
      </div>
      <button @click="redirect('/contact')" class="btn bg-zinc-100 hover:bg-zinc-400 text-black ml-3">
        Contactar
      </button>
      <!-- This is here to create the ilusion of redirecting just above the services section -->
      <section id="services"> 
      </section>
    </HeroComponent>
    
    <HeroComponent :background-color="'bg-zinc-100'">
      <div class="text-black text-3xl md:text-5xl font-bold mb-6 text-center">
        Nuestros Servicios
      </div>
      <div class="grid sm:grid-cols-1 md:grid-cols-5 gap-6 md:gap-4 text-center mb-6">
        <div
        v-for="item in services" 
        :key="item.title" 
         class="py-4 px-4 bg-white rounded-3xl shadow-2xl">
          <img :src="item.image" class="rounded-3xl mb-4 object-cover" style="width: 180px; height: 180px;" alt="Icon">
          <p class="text-black text-center text-md md:text-lg font-bold mb-4">{{ $t(item.title) }}</p>
          <button @click="redirect(item.href)"  class="btn-xs hover:bg-zinc-400 text-black ml-3 outline outline-offset-2 outline-black">
            Ver más
          </button>
        </div>
      </div>
    </HeroComponent>
  
    <HeroComponent>
      <div class="text-white text-3xl md:text-5xl font-bold mt-6 mb-6">
        Nosotros
      </div>
      <div class="text-sm text-black text-justify w-full py-4 md:w-1/2 mb-6">
        En Sfera Digital, nos especializamos en ser tu socio tecnológico ideal para llevar tu negocio al siguiente nivel en la era digital.
        Con un enfoque integral, ofrecemos una amplia gama de servicios que incluyen marketing estratégico, desarrollo de aplicaciones personalizadas y gestión experta de redes sociales.
        Nuestra pasión y dedicación están centradas en potenciar el crecimiento de tu negocio, proporcionando soluciones innovadoras y efectivas que se adaptan a tus necesidades específicas. 
      </div>
      <div class="text-sm text-black mb-10">
        ¡Nuestro interés es hacer crecer tu negocio!
      </div>
      <button @click="redirect('/aboutus')" class="btn bg-zinc-100 hover:bg-zinc-400 text-black ml-3 mb-6">
        Más información
      </button>
      <section id="clients"></section>
    </HeroComponent>
    
    <HeroComponent :background-color="'bg-zinc-100'">
      <div class="text-black text-3xl md:text-5xl font-bold mb-10 mt-6 text-center">
        Nuestros Clientes
      </div>
      <div class="md:w-1/2 grid grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <div
        v-for="item in images" 
        :key="item.key">
          <img :src="item.image" class="block md:hidden rounded-3xl mb-4 shadow-2xl object-cover" style="width: 150px; height: 150px;" alt="Icon">
        </div>
        <div
        v-for="item in images" 
        :key="item.key">
          <img :src="item.image" class="hidden md:block rounded-3xl mb-4 shadow-2xl object-cover" style="width: 180px; height: 180px;" alt="Icon">
        </div>
      </div>
    </HeroComponent>
  
    <FooterComponent/>
  </main>
</template>

<script>
import Swal from 'sweetalert2';

import LogoSvg from '../components/LogoSvg.vue'
import api from '../interceptors/axiosInterceptos'; 
import NavBar from '@/partials/NavBar.vue';
import LogoWTextSvg2 from '@/components/LogoWTextSvg2.vue';
import HeroComponent from '@/components/HeroComponent.vue';
import FooterComponent from '@/partials/FooterComponent.vue';

export default {
  name: 'LandingPage',
  components: {
    NavBar,
    HeroComponent,
    LogoWTextSvg2,
    FooterComponent
  },
  data() {
    return {
      services: [
      { title: 'labels.digitalMarketing', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717895333/corporate-management-strategy-solution-branding-concept_uyfizr.png', href: '/services/digital-marketing' },
      { title: 'labels.socialMedia', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717895333/hands-holding-smartphone-social-media-concept_u18ivh.png', href: '/services/social-media' },
      { title: 'labels.offlineMarketing', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717895332/group-people-doing-journalism-interview_1_vhxgev.png', href: '/services/offline-marketing' },
      { title: 'labels.webDevelopment', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717974487/freepik-export-20240609230130f5TI_1_ikdd8m.png', href: '/services/web-development' },
      { title: 'labels.appDevelopment', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717895332/representation-user-experience-interface-design_assvjc.png', href: '/services/app-development' },
      ],
      images: [
        {key: '1', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717896037/apeks_fyyapi.png'},
        {key: '2', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717896037/productos-grecia_rqtigq.png'},
        {key: '3', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717896037/smer_cby0d1.png'},
        {key: '4', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717896037/corner-care_xyfmql.png'},
        {key: '5', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717974500/aurea_piaune.png'},
        {key: '6', image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717896035/ji-group_ppvduu.png'},
    ]
    };
  },
  methods: {
      redirect(page){
        this.$router.push(page);
      }
    },
  
}
</script>