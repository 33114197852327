// axiosInterceptor.js
import axios from 'axios';
import Swal from 'sweetalert2';
import router from '../router'

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.API_URL, // Your API base URL
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // Successful response
    return response;
  },
  (error) => {
    // Error response
    if (error.response && error.response.status === 403) {
      // Unauthorized request
      // Show a SweetAlert notification
      Swal.fire({
        icon: 'error',
        title: 'Unauthorized',
        text: 'Your session has expired or you are not authorized. Please log in again.',
      }).then(() => {
        // Delete the token from localStorage
        localStorage.removeItem('token');

        // Redirect to the login page
        router.push('/'); // Make sure to import 'router' and set up your routes
      });
    }

    return Promise.reject(error);
  }
);

export default api;