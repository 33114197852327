import { createRouter, createWebHistory } from 'vue-router'

import { validateSession } from '../middleware/middleware';

import { 
  LandingPage,
  ContactPage,
  ClientsPage,
  AboutusPage,
  SocialMediaPage,
  WebDevelopmentPage,
  AppDevelopmentPage,
  OfflineMarKetingPage,
  DigitalMarKetingPage,
} from './pages';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/services/digital-marketing',
    name: 'DigitalMarKetingPage',
    component: DigitalMarKetingPage,
  },
  {
    path: '/services/web-development',
    name: 'WebDevelopmentPage',
    component: WebDevelopmentPage,
  },
  {
    path: '/services/social-media',
    name: 'SocialMediaPage',
    component: SocialMediaPage,
  },
  {
    path: '/services/offline-marketing',
    name: 'OfflineMarKetingPage',
    component: OfflineMarKetingPage,
  },
  {
    path: '/services/app-development',
    name: 'AppDevelopmentPage',
    component: AppDevelopmentPage,
  },
  {
    path: '/aboutus',
    name: 'AboutusPage',
    component: AboutusPage,
  },
  {
    path: '/clients',
    name: 'ClientsPage',
    component: ClientsPage,
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    const isAuthenticated = await validateSession();
    if (!isAuthenticated) {
      next('/'); // Redirect to login page if not authenticated
    } else {
      next(); // Proceed to the requested route
    }
  } else {
    next(); // Proceed to the requested route (no authentication required)
  }
});

export default router