// src/store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    isAuthenticated: false,
  },
  mutations: {
    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
  },
  actions: {
    login({ commit }) {
      // Perform your authentication logic here
      // For simplicity, just setting isAuthenticated to true
      commit('setAuthenticated', true);
    },
    logout({ commit }) {
      // Perform your logout logic here
      commit('setAuthenticated', false);
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
  },
});
