<template>
  <main class="bg-white dark:bg-slate-900">
    <NavBar/>
    <HeroComponent class="mt-16">
        <div class="text-white text-3xl md:text-5xl font-bold mt-6 mb-6">
          {{ $t('labels.appDevelopment') }}
        </div>
    </HeroComponent>
    <HeroComponent :background-color="'bg-zinc-100'">
      <p class="text-black text-center text-md md:text-lg font-bold mb-10">Tecnologías</p>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-x-20 w-2/3 mb-6">
        <div
        v-for="category in categories.slice(0,3)"
        :key="category.title" class="text-center">
          <div class="grid grid-rows-2">
            <p class="text-black text-center text-md md:text-lg font-bold mb-10 ">{{category.title}}</p>
            <div 
              v-for="set in category.sets"
              :key="set.id"
              class="flex items-center justify-center"
            >
              <div 
                v-for="(image, index) in set.images"
                :key="image.id"
                :class="['flex items-center justify-center mb-10', { 'mr-10': index !== set.images.length - 1 }]"
              >
                <img 
                  :src="image.image" 
                  :class="['rounded-xl', image.color]" 
                  style="width: auto; height: 50px;" 
                  alt="Icon"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-20 w-2/3">
        <div
        v-for="category in categories.slice(3,5)"
        :key="category.title" class="text-center">
          <div class="grid grid-rows-2">
            <p class="text-black text-center text-md md:text-lg font-bold mb-10 ">{{category.title}}</p>
            <div 
              v-for="set in category.sets"
              :key="set.id"
              class="flex items-center justify-center"
            >
              <div 
                v-for="(image, index) in set.images"
                :key="image.id"
                :class="['flex items-center justify-center mb-10', { 'mr-10': index !== set.images.length - 1 }]"
              >
                <img 
                  :src="image.image" 
                  :class="['rounded-sm', image.color]" 
                  style="width: auto; height: 50px;" 
                  alt="Icon"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeroComponent>
    <ServicesTemplate 
    :title="$t('labels.appDevelopment')" :services="services"
    />
    <FooterComponent/>
  </main>
</template>

<script>
import Swal from 'sweetalert2';

import NavBar from '@/partials/NavBar.vue';
import { services } from '@/utils/services';
import HeroComponent from '@/components/HeroComponent.vue';
import FooterComponent from '@/partials/FooterComponent.vue';
import ServicesTemplate from '@/partials/ServicesTemplate.vue';

export default {
  name: 'AppDevelopmentPage',
  components: {
    NavBar,
    HeroComponent,
    FooterComponent,
    ServicesTemplate
  },
  data() {
    return {
      services: services.appDevelopment,
      categories : 
        [
          {title: 'Despliegues', sets: [
            {
              id: 1,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717972982/playstore_umtsim.png', color: null},
                {id: 2, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717972950/app-store_tnk5oe.png', color: null},
              ]
            },
          ]},
          {title: 'UI/UX', sets: [
            {
              id: 1,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717963685/css_pg45iu.png', color: null},
                {id: 2, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717965694/tailwind_idxihf.png', color: null},
              ]
            },
            {
              id: 2,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717965693/bootstrap_t0yjkj.png', color: null},
                {id: 2, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717965691/figma_vjm8ae.png', color: null},
              ]
            },
          ]},
          {title: 'Movil', sets: [
            {
              id: 1,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717972949/dart_q8qnxk.png', color: null},
                {id: 2, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717972950/flutter_jsrilt.png', color: null},
              ]
            },
            {
              id: 2,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717972949/kotlin_aw7md2.png', color: null},
              ]
            },            
          ]},
          {title: 'Back-end', sets: [
            {
              id: 1,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717963687/javascript_fvcwni.png', color: null},
                {id: 2, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717963688/Java_mah96u.png', color: null},
              ]
            },
            {
              id: 2,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717965695/django_jlqehn.png', color: null},
                {id: 2, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717963686/fastapi_qxw1bf.png', color: null},
              ]
            },
            {
              id: 3,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717965695/nodejs_tnthra.png', color: null},
              ]
            },
          ]},
          {title: 'Base de Datos', sets: [
            {
              id: 1,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717965690/postgres_j6wpnz.png', color: null},
                {id: 2, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717965799/MySQL_pvl6jr.png', color: null},
              ]
            },
            {
              id: 2,images: [
                {id: 1, image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717963687/mongodb_assnhz.png', color: null},
              ]
            },
          ]},
        ]
    };
  },
  methods: {
    },
  
}
</script>