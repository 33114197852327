<template>
    <footer class="bg-[#909090] py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-center">
        <!-- Logo -->
        
          <LogoWCopySvg class="h-13 w-auto mb-4 mr-10"/>
  
        <SocialMediaLinks/>
        
      </div>
      <div class="flex flex-col items-center">
</div>
    </footer>
  </template>
  
  <script>
  import LogoWCopySvg from '@/components/LogoWCopySvg.vue';
  import SocialMediaLinks from '@/partials/SocialMediaLinks.vue';
  
  export default {
    name: 'FooterComponent',
    components: {
      LogoWCopySvg,
      SocialMediaLinks,
    }
  }
  </script>
  
  <style scoped>
  /* Add any additional styles you need here */
  </style>
  