// i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    // English language configurations
    labels: {
      users: 'Users',
      roles: 'Roles',
      products: 'Products',
      sources: 'Sources',
      prospects: 'Prospects',
      success: 'Success!',
      welcome: 'Welcome',
      login: 'Login',
      loading: 'Loading',
      actions: 'Actions',
      name: 'Name',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      forgotPassword: 'Forgot your password?',
      resetPassword: 'Reset it here',
      description: 'Description',
      priority: 'Priority',
      maximumAge: 'Maximum Age',
      minimumAge: 'Minimum Age',
      minimumBalance: 'Minimum Balance',
      minimumInfonavitPoints: 'Minimum Points',
      maximumLastSalary: 'Max Last Salary',
      canHaveCredit: 'Can Have Credit',
      creditMustNotBeActive: 'Credit Must Not Be Active',
      mustHaveCredit: 'Must have credit',
      minimumWeeks: 'Minimum Weeks',
      minimumDiscountedWeeks: 'Min Discounted W',
      category: 'Category',
      isLaw73: 'Law 73',
      isActive: 'Active',
      activeBefore1982: 'Active Before 1982',
      canVisualizeQuotedWeeksReport: 'Visualize Report',
      minimumDaysInactive: 'Minimum Days Inactive',
      maximumDaysInactive: 'Maximum Days Inactive',
      fractionMaximumQuotedWeeksInactive: 'Max Inactive Q/Weeks',
      precandidate: 'Product which im precandidate',
      telephone: 'Telephone',
      curp: 'Curp',
      nss: 'Nss',
      balance: 'Balance',
      quotedWeeks: 'Quoted Weeks',
      discountedWeeks: 'Discounted Weeks',
      noRecordsFound: 'No records found',
      error: 'Error',
      dob: 'Date of Birth',
      createdAt: 'Created At',
      created: 'Created',
      appointment: 'Appointment',
      call: 'Call',
      contract: 'Contract',
      done: 'Done',
      rejected: 'Rejected',
      status: 'Status',
      multiselectPlaceHolder: 'Search or add a tag',
      questions: 'Questions',
      steps: 'Steps',
      contracts: 'Contracts',
      documents: 'Documents',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      home: 'Home',
      aboutUs: 'About Us',
      services: 'Services',
      clients: 'Clients',
      contact: 'Contact',
      digitalMarketing: 'Digital Marketing',
      socialMedia: 'Social Media',
      offlineMarketing: 'Offline Marketing',
      webDevelopment: 'Web Development',
      appDevelopment: 'App Development',
      // Add other labels here
    },
    buttons: {
      add: 'Add',
      save: 'Save',
      submit: 'Submit',
      update: 'Update',
      cancel: 'Cancel',
      edit: 'Edit',
      delete: 'Delete',
      changePassword: 'Change password',
      createNewRole: 'Create new role',
      clearFilter: 'Clear All Filters',
      previous: 'Previous',
      next: 'Next',
      logout: 'Logout',
      filters: 'Filters',
      apply: 'Apply'
      // Add other button labels here
    },
    // Add other text configurations here
  },
  es: {
    // Spanish language configurations
    labels: {
      users: 'Usuarios',
      roles: 'Roles',
      products: 'Productos',
      sources: 'Promotores',
      prospects: 'Prospectos',
      success: 'Exito!',
      welcome: 'Bienvenido',
      login: 'Iniciar Sesion',
      loading: 'Cargando',
      actions: 'Acciones',
      name: 'Nombre',
      username: 'Nombre de usuario',
      email: 'Correo Electronico',
      password: 'Contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      resetPassword: 'Reestablecela aqui',
      description: 'Descripcion',
      priority: 'Prioridad',
      maximumAge: 'Edad Maxima',
      minimumAge: 'Edad Minima',
      minimumBalance: 'Balance Minimo',
      minimumInfonavitPoints: 'Puntos Minimos',
      maximumLastSalary: 'Max Ultimo Salario',
      canHaveCredit: 'Credito',
      creditMustNotBeActive: 'Credito Activo',
      mustHaveCredit: 'Debe tener Credito',
      minimumWeeks: 'Semanas Minimas',
      minimumDiscountedWeeks: 'Min Sem Descontadas',
      category: 'Categoria',
      isLaw73: 'Ley 73',
      isActive: 'Activo',
      activeBefore1982: 'Activo antes de 1982',
      canVisualizeQuotedWeeksReport: 'Visualiza Reporte',
      minimumDaysInactive: 'Dias inactivos Minimos',
      maximumDaysInactive: 'Dias inactivos Maximos',
      fractionMaximumQuotedWeeksInactive: 'Max Inactivo C/Semanas',
      precandidate: 'Producto del cual es precandidato',
      telephone: 'Telefono',
      curp: 'Curp',
      nss: 'Nss',
      balance: 'Balance',
      quotedWeeks: 'Semanas Cotizadas',
      discountedWeeks: 'Semanas Descontadas',
      noRecordsFound: 'No se encontraron registros',
      error: 'Error',
      dob: 'Fecha de nacimiento',
      createdAt: 'Fecha de creacion',
      created: 'Creado',
      appointment: 'Cita',
      call: 'Llamada',
      contract: 'Contrato',
      done: 'Finalizado',
      rejected: 'Rechazado',
      status: 'Estatus',
      multiselectPlaceHolder: 'Busca o agrega una opcion',
      questions: 'Preguntas',
      steps: 'Pasos',
      contracts: 'Contratos',
      documents: 'Documentos',
      month: 'Mes',
      week: 'Semana',
      day: 'Dia',
      home: 'Inicio',
      aboutUs: 'Nosotros',
      services: 'Servicios',
      clients: 'Clientes',
      contact: 'Contacto',
      digitalMarketing: 'Marketing Digital',
      socialMedia: 'Redes Sociales',
      offlineMarketing: 'Marketing Offline',
      webDevelopment: 'Desarrollo Web',
      appDevelopment: 'Desarrollo de Apps',
      // Add other labels here
    },
    buttons: {
      add: 'Agregar',
      save: 'Guardar',
      submit: 'Enviar',
      update: 'Actualizar',
      cancel: 'Cancelar',
      edit: 'Editar',
      delete: 'Eliminar',
      changePassword: 'Cambiar contraseña',
      createNewRole: 'Crear nuevo rol',
      clearFilter: 'Limpiar Filtros',
      previous: 'Anterior',
      next: 'Siguiente',
      logout: 'Cerrar Sesion',
      filters: 'Filtros',
      apply: 'Aplicar'
      // Add other button labels here
    },
    // Add other text configurations here
  },
  // Add configurations for other languages as needed
};

const i18n = createI18n({
  locale: 'es', // Set the default locale
  messages,
});

export default i18n;
