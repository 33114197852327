
import api from '../src/interceptors/axiosInterceptos'; 

export async function validateSession() {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(`${process.env.API_URL}/auth/validate-session/${token}`);
    return response.data.valid;
  } catch (error) {
    console.error('Error validating session:', error);
    return false;
  }
}