<template>
      <HeroComponent :background-color="'bg-zinc-100'"
      v-for="item in services" 
      :key="item.title" 
      >
        <p class="text-black text-center text-md md:text-lg font-bold mb-10">{{ item.title }}</p>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20 text-center w-2/3">
          <div class="text-md text-black text-justify place-self-center" :class="item.left ? 'hidden':'hidden md:block'">
            {{ item.content }}
          </div>
          <div class="place-self-center">
            <img :src="item.image" class="rounded-3xl mb-4" style="width: auto; height: 220px;" alt="Icon">
          </div>
          <div class="text-md text-black text-justify place-self-center" :class="item.left ? 'block':'block md:hidden'">
            {{ item.content }}
          </div>
        </div>
      </HeroComponent>
      <HeroComponent class="border-2 border-b-black">
        <div class="text-white text-3xl md:text-5xl font-bold mt-6 mb-6">
          La Voz de Nuestros Clientes
        </div>
        <CarrouselComponent :slides="this.slides" class="mb-10"/>
      </HeroComponent>
      
  </template>
  
  <script>
  import Swal from 'sweetalert2';
  
  
  import LogoWTextSvg2 from '@/components/LogoWTextSvg2.vue';
  import HeroComponent from '@/components/HeroComponent.vue';
  import CarrouselComponent from '@/components/CarrouselComponent.vue';
  
  export default {
    name: 'ServicesTemplate',
    components: {
      HeroComponent,
      CarrouselComponent
    },
    props: {
        title: String,
        services: Array
    },
    data() {
      return {
        slides:  [
            { 
                content: 'Gracias por ayudarme con la identidad de mi marca y redes sociales, mis clientes se sienten más atraídos de adquirir mis productos.',
                image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717896037/productos-grecia_rqtigq.png' 
            },
            { 
                content: 'Su servicio de publicidad en redes sociales y el marketing de contenidos nos ha permitido llegar a más clientes en toda la ciudad.',
                image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717896037/smer_cby0d1.png' 
            },
            {
              content: 'Gracias a que nos ayudaron a desarrollar una app para nuestros servicios podemos ofrecerlos de manera masiva en toda la ciudad. Indudablemente potencializo nuestro crecimiento.',
              image: 'https://res.cloudinary.com/dgcny5frd/image/upload/v1717896037/corner-care_xyfmql.png' 
            }
          // Add more slides as needed
        ],
      };
    },
    methods: {
      },
    
  }
  </script>