<template>
  <div
    class="relative w-full md:w-2/3"
    v-touch:swipe.left="nextSlide"
    v-touch:swipe.right="prevSlide"
    @mouseenter="stopAutoSwipe"
    @mouseleave="startAutoSwipe"
  >
    <!-- Slides -->
    <div class="overflow-hidden">
      <div
        class="flex transition-transform duration-500"
        :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
      >
        <div
          v-for="(slide, index) in slides"
          :key="index"
          class="min-w-full place-self-center"
        >
          <div class="md:flex items-center justify-center text-center py-10 px-20 bg-zinc-100 ">
            <div class="flex justify-center md:hidden">
              <img :src="slide.image" class="rounded-full mb-4" style="width: 100px; height: 100px;" alt="Icon">
            </div>
            <div class="place-self-center pr-20 hidden md:block">
              <img :src="slide.image" class="rounded-full mb-4" style="width: 150px; height: 150px;" alt="Icon">
            </div>
            <div class="text-md text-black text-center md:text-justify place-self-center w-full md:w-2/3 italic">
              <div class="line-clamp-4 ">
                {{ slide.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Navigation buttons -->
    <button
      class="absolute top-1/2 left-0 transform -translate-y-1/2 text-black p-2 ml-5 rounded-full"
      @click="prevSlide"
    >
      &lt;
    </button>
    <button
      class="absolute top-1/2 right-0 transform -translate-y-1/2 text-black p-2 mr-5 rounded-full"
      @click="nextSlide"
    >
      &gt;
    </button>
    <!-- Indicators -->
    <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
      <span
        v-for="(slide, index) in slides"
        :key="index"
        class="h-3 w-3 rounded-full"
        :class="index === currentSlide ? 'bg-gray-500' : 'bg-white'"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarrouselComponent',
  props: {
    slides: Array  
  },
  data() {
    return {
      currentSlide: 0,
      autoSwipeInterval: null,
    };
  },
  created() {
    // Start auto-swipe when the component is created
    this.startAutoSwipe();
  },
  beforeUnmount() {
    // Clear the auto-swipe interval when the component is destroyed
    clearInterval(this.autoSwipeInterval);
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
    startAutoSwipe() {
      // Set the auto-swipe interval to call nextSlide every 3 seconds
      this.autoSwipeInterval = setInterval(() => {
        this.nextSlide();
      }, 3000); // Change this value as needed
    },
    stopAutoSwipe() {
      // Clear the auto-swipe interval when the mouse enters the carousel
      clearInterval(this.autoSwipeInterval);
    },
  },
};
</script>

<style scoped>
/* Additional styling if needed */
</style>
